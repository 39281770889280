.memberLog {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 77vh;
}

.aside {
    width: 35%;
    height: 80vh;
    overflow-y: scroll;
}

.aside h1 {
    text-align: center;
}

.aside::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #000000;
}

.aside::-webkit-scrollbar {
    width: 10px;
    background-color: #ffffff;
}

.aside::-webkit-scrollbar-thumb {
    background-color: #2e2e2e;
    border: 2px solid #000000;
}

.new-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    width: 60%;
    margin: 0 10px;
    height: 80vh;
}

.oneNew {
    display: flex;
    align-items: center;
    height: 100px;
    width: 90%;
    background-color: rgb(21, 33, 71);
    margin-top: 0.5em;
    padding: 0 1em;
}

.oneNew:nth-child(odd) {
    background-color: rgb(83, 23, 23);
}

.user-new {
    font-family: anton;
    font-weight: 100;
    text-transform: uppercase;
}


.new-center img {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .memberLog {
        flex-direction: column-reverse;
        height: 100vh;
    }

    .new-center img {
        display: none;
    }

    .aside {
        width: 500px;
        margin: 0 auto;
    }

    .new-center {
        width: 100%;
        margin: 0;
    }

}