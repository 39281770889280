.eventArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 15vh;
    padding: 1em;
    background-color: rgb(71, 61, 123);
    border-radius: 5px;
    margin: 4vw 4vh;
}

.eventArea h1 {
    font-family: Anton;
    font-weight: 100;
    color: rgb(0, 0, 0);
    font-size: 3em;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .eventArea h1 {
        font-size: 1.5em;
    }

    .eventArea {
        height: 10vh;
    }
}