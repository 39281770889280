.admin-start {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: rgb(26, 26, 26);
}

.view-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .view-container {
        flex-direction: column;
    }
}

@media screen and (min-width: 768px) {}