.container {
    width: 100%;
    background-color: #1a1a1a;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1em;
    gap: 50px;
}

.color {
    font: 1.2em 'anton';
    display: flex;
    width: 10vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.colorBox {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin: 1em;
}

.backgroundRed {
    background-color: rgb(204, 37, 37);
    margin: 0;
}

.backgroundBlue {
    background-color: rgb(49, 49, 197);
    margin: 0;
}

.backgroundGray {
    background-color: #444444;
    margin: 0;
}