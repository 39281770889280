.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;
    width: 80vw;
    height: 150px;
    background-color: rgb(136, 52, 52);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 72.32px), calc(100% - 23px) calc(100% - 23px), calc(100% - 72.32px) 100%, 72.32px 100%, 23px calc(100% - 23px), 0 calc(100% - 72.32px));
}

.stage h1 {
    font-family: 'anton', 'arial';
    font-weight: 100;
    color: rgb(0, 0, 0);
    font-size: 3em;
    margin: 0;
}

.info {
    font-size: 1.2em;
    font-weight: bold;
}

@media screen and (max-width: 600px) {

    .info {
        font-size: 1em;
        margin: 1em;
        text-align: center;
    }
    .stage {
        font-size: 0.5em;
    }
}