.seat {
    width: 65%;
    height: 30%;
    border-radius: 4px;
    background-color: rgb(47, 45, 189);
}

.seat p {
    font-family: anton;
    display: flex;
    justify-content: center;
    color: rgb(170, 170, 170);
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 0;
    margin: 0;
}

.selected {
    background-color: #303030;
    color: black;
}

.seat:hover {
    background-color: #8a8a8a;
    color: black;
    cursor: pointer;
}

.booked {
    background-color: #be1a1a;
    color: black;
    cursor: not-allowed;
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    background-color: rgb(48, 48, 48);
    padding: 20px;
    border-radius: 10px;

}

@media screen and (max-width: 768px) {
    .seat p {
        font-size: 0.6em;
    }
}