.member-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 6em auto;
    /* background-color: rgb(24, 24, 24); */
    padding-bottom: 2em;
    max-width: 1200px;
    color: rgb(196, 196, 196);
}

.member-box img {
    padding: 1em 1.2em;
    height: 30vh;
}

.member-box h1 {
    font-size: 4em;
    margin-bottom: 1em;
    color: white;
}

.member-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    padding: 0.7em 1em;
    background-color: rgb(22, 22, 22);
    color: gray;
    width: 20vw;
    height: 7vh;
    border: none;
    box-shadow: 1px 1px 1px 1px rgb(0, 0, 0);
    border-radius: 25px;
}

.member-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.member-text {
    font-size: 1.5em;
    max-width: 500px;
}

@media screen and (max-width: 1250px) {
    .member-container {
        flex-direction: column;
        width: 100%;
    }

    .member-box {
        background-color: rgb(29, 29, 29);
        margin: 0;
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {

    .member-box h1 {
        display: none;
    }


    .member-container {
        flex-direction: column;
    }

    .member-box img {
        height: fit-content;
        width: 100%;
        padding: 0;
    }

    .member-text {
        width: 80vw;
        margin-bottom: 2em;
    }

    .member-container button {
        align-items: center;
        margin: 0;
        width: 80vw;
    }

}

@media screen and (max-height: 675px) {

    .member-container img {
        display: none;
    }

    .member-text p {
        font-size: 0.8em;
    }
}