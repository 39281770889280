.info-box {
    font-family: 'arial', sans-serif;
    font-size: 1.5em;
    line-height: 1.3em;
    color: rgb(196, 196, 196);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    padding: 0;
    margin: 5em auto;


    img {
        margin: 0;
        padding: 0;
        height: 30vh;
        width: auto;
    }

    h1 {
        margin: 0;
    }



}

.info-container>button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    padding: 0.7em 1em;
    background-color: rgb(22, 22, 22);
    color: gray;
    width: 100%;
    height: 7vh;
    border: none;
    box-shadow: 1px 1px 1px 1px rgb(0, 0, 0);
    border-radius: 25px;
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;

    .socials {
        display: flex;
        justify-content: flex-start;
        gap: 1vw;
        align-items: center;
        width: 100%;
        margin-top: 1em;
    }

}

Link {
    text-decoration: none;
    color: black;
}

a:visited {
    color: white;
}

@media screen and (max-width: 1400px) {
    .info-box {
        width: 80vw;
        margin: 0 auto;

        img {
            height: 20vh;
        }
    }

}

@media screen and (max-width: 768px) {
    .info-box {
        font-size: 1.2em;
        flex-direction: column;
        margin: 1vh 10vw;

        img {
            display: none;
        }
    }

    .socials {
        display: flex;
        justify-content: center;
        gap: 1vw;
        align-items: center;
        width: 100%;
        margin-top: 1em;
    }
}