input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    font-size: 1.5em;
    border: 1px solid green;
    color: rgb(230, 230, 230);
    -webkit-box-shadow: 0 0 0px 1000px #656565 inset;
    transition: background-color 5000s ease-in-out 0s;
}

label {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}

input {
    font-size: 1.5em;
    border-radius: 2px;
    border: none;
    /* width: 100%; */
    color: white;
    background-color: rgb(102, 102, 102);
}

input::placeholder {
    color: rgb(183, 183, 183);
}

button {
    font-size: 1.5em;
    border-radius: 2px;
    border: none;
    color: white;
    padding: 0.5em;
    cursor: pointer;
    width: 50%;
}

.register-form {
    font-family: 'anton';
    letter-spacing: 0.1em;
    padding: 2em;
    color: rgb(229, 229, 229);
    background-color: rgb(30, 30, 30);
}

form>div:has(input[type="checkbox"]) {
    display: flex;
    color: rgb(229, 229, 229);
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.checkbox {
    width: 20px;
    height: 100%;
}

.clickable-text {
    color: rgb(130, 45, 45);
    cursor: pointer;
    margin: 0;
}

.clickable-text:hover {
    text-decoration: underline;
    color: #b72323;
}

.register-form {
    min-height: 40vh;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    border-radius: 2px;
}

.form-container-booking {
    max-width: 400px;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
}

.register-form>div {
    display: flex;
    flex-direction: column;
    margin: 1em;
}


.next {
    display: flex;
    align-items: center;
    width: 100%;
}

.back {
    display: flex;
    align-items: center;
    width: 100%;
}

.back>button {
    background-color: rgb(162, 57, 57);
    border: none;
    color: white;
}

.next>button {
    background-color: rgb(57, 69, 162);
    border: none;
    color: white;
}

.is-invalid {
    font-size: 1.5em;
    border: 1px solid rgb(128, 0, 0);

}

.is-valid {
    border: 1px solid green;
}

.progressbar {
    display: flex;
    justify-content: center;
    gap: 3em;
    align-items: center;
    width: 90%;
    margin: 1em auto;
}

.step {
    text-transform: uppercase;
    display: flex;
    gap: 1em;
    padding-left: 1em;
    margin-right: 0;
    background-color: rgb(21, 21, 21);
    border-bottom: 2px solid rgb(229, 229, 229);
    width: 190px;
    height: 50px;
    font-weight: 500;
}

.current-step {
    border-bottom: 3px solid rgb(215, 38, 38);
}

.confirmation-field p {
    padding: 0;
    margin: 0.3em;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: normal;
}

@media screen and (max-width: 768px) {
    .progressbar {
        gap: 0.4em;
        flex-wrap: wrap;
    }

    .register-form{
        padding: 0;
    }

    .step {
        display: none;
    }

    .form-container h1 {
        margin: 0;
    }
}