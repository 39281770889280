.event {
    overflow: scroll;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    background-color: rgba(75, 75, 75, 0.411);
    overflow: scroll;
    margin: 1em;
}

.event::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.event::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.10), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

.event::-webkit-scrollbar-corner {
    background: #00000000;
}

.eventsContainer {
    width: 45%;
}

@media screen and (max-width: 768px) {
    .events-container {
        display: none;
    }

    .event {
        width: 77%;
    }
}

.activeEvent {
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.2);
}