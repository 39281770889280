.center {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
}

.etown-logo {
    height: 15vh;
}

#submit-form {
    height: 650px;
    margin-top: 0;
    color: white;
    padding: 0;
}

input:-webkit-autofill:focus {
    color: white;
}



.form-container {
    height: auto;
}



a:hover {
    filter: brightness(0.8);
}



.etown-logo {
    display: none;
}

#submit-form {
    margin-top: 1em;
}

.spons-three-col {
    display: flex;
    flex-direction: row;
    align-items: center;
}




.next a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    background-color: rgb(34, 136, 150);
    border-radius: 5px;
    height: 50px;
}

.next a:hover {
    filter: brightness(0.8);
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}