.navbarAdmin {
    gap: 8vw;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-size: 1.3em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
}

.logout {
    cursor: pointer;
    color: rgb(189, 31, 31);
    width: 200px;
    width: fit-content;
}

body {
    background-color: #f5f5f5;
}