.user {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin: 1em;
    border-radius: 5px;
    background-color: rgb(35, 33, 49);
    overflow: scroll;
}

.red {
    color: rgb(153, 50, 50);
}

.green {
    color: rgb(19, 133, 19);
}

.user:hover {
    background-color: rgb(51, 38, 38);
}

.user h1 {
    margin: 0;
    padding: 0;
    color: rgb(177, 177, 177);
}

.user::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.user::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.10), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

.user::-webkit-scrollbar-corner {
    background: #00000000;
}

.users-container {
    margin: 1em;
    width: 45%;
}

.users-container>h1 {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    color: rgb(233, 233, 233);
}

.users-container>p {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    margin: 0;
    padding: 0;
    color: rgb(177, 177, 177);

}

.two-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    width: 100%;
}

.two-col button {
    display: flex;
    align-items: center;
    justify-self: center;
    width: 20vw;
    height: 4vh;
    margin-top: 1em;
    cursor: pointer;
    border: none;
}

.two-col p {
    margin: 0;
}

@media screen and (max-width: 768px) {
    .users-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90vw;
    }

    .user {
        width: 70vw;
    }
}