.table {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 2px;
    width: 40px;
    height: 100px;
    background-color: rgb(101, 145, 182);
    border-radius: 2px;
}

@media screen and (max-width: 768px) {
    .table {
        width: 20px;
        height: 50px;
    }
}