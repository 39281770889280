.bookingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: rgb(20, 20, 20);
}

.seatView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    margin-top: 1em;
    overflow-y: scroll;
    border: 1px solid #303030;
    margin-bottom: 1em;
}

.confirmContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.confirmContainer button {

    font-family: 'anton';
    font-size: 2em;
    width: 20vw;
    height: fit-content;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .seatView {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 75vh;
        overflow: scroll;
        border: none;
    }

    .bookingContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        background-color: rgb(20, 20, 20);
    }

    .confirmContainer button {
        font-size: 1.5em;
        width: 80vw;
        height: 10vh;
        background-color: #ffffff;
        color: rgb(0, 0, 0);
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
    }
}

@media screen and (max-width: 1200px) {
    .bookingContainer {
        width: 100vw;
    }

}




/* Kolla igenom detta om det finns duplicate */


.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 5px;
}

.row h1 {
    font-family: anton;
    font-weight: 100;
    color: rgb(167, 26, 26);
}

.row:nth-child(2n) {
    padding-right: 30px;
}

.row:last-child {
    padding-right: 0;
}

.bookingArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 47vh;
    margin: 4vw 4vh;
    gap: 2px;
}

.labelValue {
    display: flex;
    padding: 1em 0;
    justify-content: space-between;
}

.adminSlider {
    width: 100%;
    height: 25px;
    background: #d4d4d4;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.adminSlider:hover {
    opacity: 1;
}

.adminSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #0778e2;
    cursor: pointer;
}

.adminSlider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #2f20bb;
    cursor: pointer;
}