.egame-nights {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.5em;
}

.egame-nights>h1 {
    text-align: center;
    font-size: 3em;
}

.gamenight-container-two-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    gap: 8em;
    min-height: 400px;
    color: rgb(196, 196, 196);
}

.gamenight-container-two-col>.text {
    width: 500px;
}

.video-wrapper {
    position: relative;
    width: 650px;
    height: 400px;

    /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.text>h1 {
    color: rgb(255, 255, 255);
    margin-top: 0;
}

button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    padding: 0.7em 1em;
    background-color: black;
    color: gray;
    width: 90%;
    border: gray 1px solid;
    border-radius: 25px;
}

@media screen and (max-width: 1400px) {
    .gamenight-container-two-col {
        gap: 1em;
    }

    .video-wrapper {
        position: relative;
        width: 450px;
        height: 300px;

        /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    }

}

@media screen and (max-width: 1070px) {
    .egame-nights h1 {
        font-size: 2em;
    }

    .gamenight-container-two-col:nth-child(odd) {
        flex-direction: column-reverse;
    }

    .gamenight-container-two-col {
        flex-direction: column;
        align-items: center;
        gap: 1em;
        width: 90vw;
    }

    .gamenight-container-two-col>.text {
        width: 100%;
    }

    .video-wrapper {
        width: 90%;
        height: 300px;
    }

    .text {
        width: 100%;
    }

}