.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a;
    color: white;
}

.login-container input {
    margin: 10px;
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    border: none;
}

.login-container button {
    display: flex;
    margin: 10px auto;
    padding: 10px;
    width: 300px;
    border-radius: 5px;
    border: none;
    background-color: #ff4d4d;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
}

.login-container>form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}