.eventEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 5vh auto;
    height: 90vh;
    width: 90%;
    -webkit-box-shadow: 0px 0px 82px 1px rgba(71, 71, 71, 1);
    -moz-box-shadow: 0px 0px 82px 1px rgba(71, 71, 71, 1);
    box-shadow: 0px 0px 82px 1px rgba(71, 71, 71, 1);
}

.eventEditSidebar {
    background-color: rgb(49, 49, 49);
    display: flex;
    flex-direction: column;
    width: 33%;
}

.eventEditSidebar h1 {
    padding-top: 1vh;
    margin: 0 auto;
}

.eventEditSidebar form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80%;
    padding: 1em;
}

.eventEditSidebar label {
    font-size: 1.2em;
}

.eventView {
    background-color: rgb(20, 20, 20);
    width: 77%;
    padding: 1em;
    overflow: scroll;
}

.eventEdit h1 {
    margin-top: 0;
}

.instructions {
    padding: 1em;
    display: flex;
    align-items: center;
    margin: 2em auto;
    flex-wrap: wrap;
    width: 50%;
    height: 20vh;
    background-color: #a3a3a377;
    border-radius: 5px;
}

.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 5px;
}

.row h1 {
    font-family: anton;
    font-weight: 100;
    color: rgb(167, 26, 26);
}

.row:nth-child(2n) {
    padding-right: 30px;
}

.row:last-child {
    padding-right: 0;
}

.bookingArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 47vh;
    margin: 4vw 4vh;
    gap: 2px;
}

.labelValue {
    display: flex;
    padding: 1em 0;
    justify-content: space-between;
}

.adminSlider {
    width: 100%;
    height: 25px;
    background: #d4d4d4;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.adminSlider:hover {
    opacity: 1;
}

.adminSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #0778e2;
    cursor: pointer;
}

.adminSlider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #2f20bb;
    cursor: pointer;
}