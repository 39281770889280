.smaller {
    min-width: 200px;
    width: 400px;
    max-width: 600px;
    padding: 2em;
    margin: 1em;
}

.center {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 80vh;
}

.eventInfo p {
    text-align: center;
    font-size: 1.2em;
    width: 500px;
    margin: 1em auto;
}

.eventInfo h1 {
    text-align: center;
    /* margin: 0.5em; */
}

.smaller h1 {
    margin: 0;
}

.eventRed {
    background-color: rgb(34, 19, 19);
    box-shadow: 0px 0px 1px 1px #120e0e;
}

.eventBlue {
    background-color: rgb(22, 22, 33);
    box-shadow: 0px 0px 1px 1px #120e0e;
}

.smaller:hover {
    transform: scale(1.1);
    transition: 0.5s;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .smaller {
        width: 80%;
    }

    .eventInfo p {
        width: 80%;
        margin: 1.5em auto;
    }

    .eventInfo h1 {
        font-size: 2em;
    }
}