.footer {
    font-family: "Oswald", sans-serif;
    color: rgb(114, 114, 114);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgb(35, 35, 35);
    background: linear-gradient(180deg, rgb(35, 35, 35) 2%, rgba(255, 255, 255, 1) 11%);
}

footer {
    width: 100%;
    min-height: 400px;
}

.top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
}

.top img {
    height: 50px;
    padding: 1em;
}

.bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    padding: 1em 0;
}

.footer h2 {
    font-size: 1.1em;
    margin-bottom: 1em;
    border-bottom: 1px solid rgb(58, 58, 58);
}

.footer a,
.footer p {
    font-size: 0.8em;
    margin: 0;
    color: rgb(43, 43, 43);
}

.footerInfo .socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.footerInfo {
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    width: 20vw;
    height: inherit;
    max-width: 33vw;
}

.eventSponsors {
    text-align: center;
    margin-top: 100px;
    width: 100%;
    gap: 1em;
    /* background-color: rgb(255, 255, 255); */
}

.eventSponsors img {
    display: flex;
    flex-direction: row;
    max-width: 230px;
    max-height: 95px;
    width: auto;
    height: auto;
}

.sponsThreeCol {
    margin: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    justify-content: center;
}

@media screen and (max-width : 768px) {
    .eventSponsors img {
        max-width: 230px;
        max-height: 95px;
        flex-wrap: wrap;
        width: 23%;
        height: auto;
    }

    .bottom {
        flex-direction: column;
        align-items: center;
    }

    .footerInfo {
        width: 100%;
        max-width: 80%;
        margin: 1em;
    }

    .footerInfo h2 {
        margin: 0;
        border: none;
        font-size: 1.5em;
    }

    .footerInfo .socials {
        margin: 0;
        justify-content: flex-start;
    }
}